import cn from 'classnames'
import {
  faLongArrowAltUp,
  faLongArrowAltDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { col, colWide, breakLine } from '~/components/Table/Table.scss'
import { humanDateTime } from '~/utils/time'
import SegmentsRowActions from './SegmentsRowActions'
import GroupsColumn from '~/components/Table/components/GroupsColumn'
import Link from '~/components/Link'
import routes from '~/routes'
import { useStore } from '~/dataStore'
import colors from '~/utils/color'
import {
  ISegmentListItem,
  SEGMENT_STATUS
} from '~/pages/Segment/SegmentsList/SegmentsList.interface'
import Pill from '~/components/Pill'
import styles from '~/components/Filters/StatusPill.scss'

const handleSufix = () => (
  <span className="ms-1 fw-bold" style={{ color: colors.blueGray }}>
    <span style={{ fontSize: 12 }}>(</span>7 days
    <span style={{ fontSize: 12 }}>)</span>
  </span>
)

const handleUsersDiff = (users: number) => {
  if (users > 0) {
    return (
      <>
        {users}
        <FontAwesomeIcon
          icon={faLongArrowAltUp}
          fixedWidth
          color={colors.info}
        />
      </>
    )
  }
  if (users < 0) {
    return (
      <>
        {users}
        <FontAwesomeIcon
          icon={faLongArrowAltDown}
          fixedWidth
          color={colors.danger}
        />
      </>
    )
  }
  return <>{users}</>
}

const groupsColumn = ({
  row,
  onUpdateRow
}: {
  row: ISegmentListItem
  onUpdateRow: (row: unknown) => void
}) => <GroupsColumn {...row} onUpdateRow={onUpdateRow} />

const segmentsTableColumns = () => {
  const {
    app: { currentApp }
  } = useStore()

  return [
    {
      fieldName: 'name',
      displayName: 'Name',
      sortable: true,
      className: cn(col, colWide),
      render: ({ row: { name, id } }: { row: ISegmentListItem }) => (
        <Link
          route={routes.segmentUsersList}
          params={{ appId: currentApp.id, segmentId: id }}
          className={cn(col, colWide, breakLine)}>
          {name}
        </Link>
      )
    },
    {
      fieldName: 'createdAt',
      displayName: 'Date Created',
      sortable: true,
      render: ({ row: { createdAt } }: { row: ISegmentListItem }) =>
        humanDateTime(createdAt)
    },
    {
      fieldName: 'cachedUsersCount',
      displayName: 'Total Users',
      sortable: true,
      className: cn(col, colWide)
    },
    {
      fieldName: 'usersDiff',
      displayName: 'User Trends',
      className: cn(col, colWide),
      render: ({ row: { usersDiff } }: { row: ISegmentListItem }) =>
        handleUsersDiff(usersDiff),
      sufix: () => handleSufix()
    },
    {
      fieldName: 'status',
      displayName: 'Status',
      render: ({ row: { status } }: { row: ISegmentListItem }) => (
        <Pill
          color={status === SEGMENT_STATUS.READY ? 'primary' : 'gray'}
          className="px-4 py-2">
          {status === SEGMENT_STATUS.READY ? 'Ready' : 'In Progress'}
        </Pill>
      ),
      className: cn(col, colWide, 'groups-row')
    },
    {
      fieldName: 'groups',
      displayName: 'Groups',
      render: groupsColumn,
      className: cn(col, colWide, 'groups-row')
    },
    {
      displayName: 'Actions',
      render: SegmentsRowActions
    }
  ]
}

export default segmentsTableColumns
